<template>
  <div class="container">
    <div class="form-background" v-show="formShow" >
      <div class="form-back container">
        <Profile ref="editForm" @close="formShow=false" />
      </div>
    </div>
    <Confirm ref="delConfirm" @close="delShow=null" @confirm="finishDel(delShow)" v-show="delShow">
      Delete {{ delShow ? `${delShow.first_name} ${delShow.last_name}`: "" }}?
    </Confirm>
    <div class="page-top">
        <h1 class="is-size-3">User Profiles</h1>
        <div class="controls" v-if="editor">
          <button class="button" id="add-profile" @click="$refs.editForm.clearForm(); formShow=true">
            <span class="icon is-small">
              <i class="fas fa-plus"></i>
            </span>
            <span>Add Profile</span>
          </button>
        </div>
      </div>
    <div id="profile-list">
      <div class="table-head">Name</div>
      <div class="table-head">Email</div>
      <div class="table-head">Last Login</div>
      <div class="table-head">Role</div>
      <div>&nbsp;</div>
      <template v-for="p in profiles">
        <div class="profile-name" :key="`${p.member_id}-name`">
          {{ p.first_name }} {{ p.last_name }}
        </div>
        <div class="profile-email" :key="`${p.member_id}-email`">
          <a :href="`mailto:${p.email}`">{{ p.email }}</a>
        </div>
        <div class="profile-login" :key="`${p.member_id}-last-login`">
          {{ p.last_login ? new Date(p.last_login).toLocaleString() : "Never" }}
        </div>
        <div class="profile-group" :key="`${p.member_id}-group`">
          <span class="tag is-success is-light" v-show="p.user_type===1">User</span>
          <span class="tag is-info is-light" v-show="p.user_type===2">Editor</span>
          <span class="tag is-warning is-light" v-show="p.user_type===4">Admin</span>
          <span class="tag is-danger is-light" v-show="p.user_type===8">Admin+</span>
        </div>
        <div :key="`${p.member_id}-actions`" class="actions">
          <nobr v-show="user.user_type === 8 || (user.user_type > 2 && user.user_type > p.user_type)">
            <span class="icon edit" @click="editProfile(p)">
              <i class="fas fa-pencil-alt"></i>
            </span>
            <span class="icon delete" @click="delShow=p">
              <i class="fas fa-trash-alt"></i>
            </span>
          </nobr>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Confirm from "./Confirm";
import Profile from "./Profile";
import noUserToFront from "../mixins/noUserToFront";
import tokenExpiration from "../mixins/tokenExpiration";

export default {
  name: "ProfileList",
  mixins: [noUserToFront, tokenExpiration],
  components: { Confirm, Profile },
  data () {
    return {
      delShow: null,
      formShow: null
    }
  },
  computed: {
    user () {
      const u = window.localStorage.getItem("constack");
      if (u) {
        return JSON.parse(u);
      }
      return { user_type: 1 }
    },
    editor () {
      if (this.user.user_type > 1) {
        return true;
      }
      return false;
    },
    profiles () {
      if (this.$store.state.profiles && this.$store.state.profiles.length > 0) {
        return this.$store.state.profiles.slice().sort((a, b) => {
          return `${a.last_name}${a.first_name}`.localeCompare(`${b.last_name}${b.first_name}`)
        })
      }
      return []
    }
  },
  methods: {
    finishDel(profile) {
      const vm = this;
      this.$store.dispatch("deleteProfile", profile)
      .then(async function (response) {
        await vm.$store.commit('deleteProfile', response.data.id);
        vm.delShow = null;
        // console.log("RESPONSE", response.data.id);
      })
      .catch(err => {
        console.log("ERROR", err);
      })
    },
    editProfile(profile) {
      // console.log("EDITING", profile.first_name, profile.last_name);
      this.$refs.editForm.setProfile(profile);
      this.formShow = true;
    }
  },
  mounted () {
    this.$store.dispatch("fetchProfiles");
  // },
  // beforeRouteEnter (to, from, next) {
  //   next(vm => {
  //     const localUser = window.localStorage.getItem("constack");
  //     if ((localUser !== null) && (vm.$store.state.user === null)) {
  //       vm.$store.commit("setUser", JSON.parse(window.localStorage.getItem("constack")))
  //     }
  //     const user = vm.$store.state.user;
  //     if (user === null || user.user_type < 4) {
  //       vm.$router.push("/");
  //     }
  //   });
  }
}
</script>

<style scoped>
  #profile-list {
    display: grid;
    grid-template-columns: auto auto auto min-content min-content;
    grid-gap: 8px;
  }

  #profile-list > div {
    padding: 0 2px;
  }

  .table-head {
    border-bottom: 1px solid black;
  }

  .actions {
    justify-self: stretch;
    margin: 0 10px;
    min-width: 50px;
  }

  .actions > nobr {
    display: flex;
    justify-content: space-evenly;
  }

  .edit:hover {
    color: var(--trmn-orange);
  }

  span.delete:hover {
    background-color: var(--trmn-red);
  }

  .profile-group {
    margin: 0 10px;
  }

.page-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 8px;
}

button#add-profile {
  background-color: var(--trmn-red);
  color: white;
}

button#add-profile:hover {
  background-color: white;
  color: var(--trmn-red);
}

.form-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 100;
}

.form-back {
  background-color: white;
  padding: 20px;
  margin-top: 5rem;
  border-radius: 1rem;
}

.tag {
  width: 100%;
}
</style>
