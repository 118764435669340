<template>
  <div id="profile-form">
    <div class="title">
      {{ add ? "Add" : "Edit" }} Profile
    </div>
    <horizontal-input v-model="profile.member_id" label="RMN ID" :disabled="!add" :errMessage="rmnIdError" />
    <horizontal-input v-model="profile.first_name" label="First Name" />
    <horizontal-input v-model="profile.last_name" label="Last Name" />
    <horizontal-input v-model="profile.email" label="Email" type="email" :errMessage="emailError" />
    <horizontal-select v-model="profile.user_type" label="Role" :choices="roles" keyField="id" valField="name" />
    <div class="button-container">
      <div class="buttons">
        <button class="button cancel" @click="$emit('close')">Cancel</button>
        <button class="button is-link" v-if="add" @click="save">Add</button>
        <button class="button is-link" v-else @click="save">Save</button>
      </div>
    </div>
  </div>
</template>

<script>
import HorizontalInput from '../components/HorizontalInput';
import HorizontalSelect from '../components/HorizontalSelect';

const ROLES = [
  { id: 1, name: "User" },
  { id: 2, name: "Editor" },
  { id: 4, name: "Admin" },
  { id: 8, name: "Admin+" }
]

const PROFILE = {
  member_id: null,
  first_name: null,
  last_name: null,
  email: null,
  user_type: 1
}

export default {
  name: "ProfileForm",
  components: { HorizontalSelect, HorizontalInput },
  data () {
    return {
      add: false,
      profile: {
        member_id: null,
        first_name: null,
        last_name: null,
        email: null,
        user_type: 1
      },
      rmnIdError: null,
      emailError: null
    }
  },
  computed: {
    user () {
      if (this.$store.state.user) {
        return this.$store.state.user
      }
      return { user_type: 1 }
    },
    roles () {
      if (this.user.user_type > 2) {
        return ROLES.filter(x => x.id <= this.user.user_type)
      }
      return [];
    },
    validId() {
      return this.validateId();
    }
  },
  methods: {
    validateEmail() {
      if (!this.profile.email || this.profile.email === "") {
        return false;
      }

      if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(this.profile.email)) {
        return false;
      }

      return true;
    },
    validateId() {
      if (this.profile.member_id === "") {
        return false;
      }

      if(!/RMN-\d{4}-\d{2}/.test(this.profile.member_id)) {
        return false;
      }

      return true;
    },
    setProfile(profile) {
      this.profile = { ...profile }
      this.rmnIdError = null;
      this.emailError = null;
      // console.log(`PROFILE "${profile.member_id}"`);
      if (profile.member_id && profile.member_id !== "") {
        this.add = false;
      } else {
        this.add = true;
      }
    },
    clearForm() {
      this.profile = { ...PROFILE };
      this.rmnIdError = null;
      this.emailError = null;
      this.add = true;
    },
    save () {
      const validRmnId = this.validateId();
      const validEmail = this.validateEmail();
      if (!validRmnId) {
        this.rmnIdError = "RMN ID must be supplied and be in the form RMN-####-##"
      } else {
        this.rmnIdError = null;
      }

      if (!validEmail) {
        this.emailError = "Email address is required and must be a valid email address"
      } else {
        this.emailError = null;
      }

      if (!validRmnId || !validEmail) {
        return;
      }
      // console.log("SAVE PROFILE");
      const vm = this;
      this.profile.user_type = parseInt(this.profile.user_type);

      if (!this.add) {
        this.$store.dispatch('putProfile', this.profile)
        .then(async () => {
          vm.$store.commit('updateProfile', this.profile);
          // console.log("PUT WORKED", response.data.id);
          vm.$emit('close');
        })
        .catch(err => {
          console.error("PUT FAILED", err);
        })
      } else {
        this.$store.dispatch('postProfile', this.profile)
        .then(async () => {
          vm.$store.commit('updateProfile', this.profile);
          // console.log("POST WORKED", response.data.id);
          vm.$emit('close');
        })
        .catch(function (error) {
          console.log(error);
        });
      }
    }
  }
}
</script>

<style scoped>
.button-container {
  margin-top: 24px;
  display: flex;
  justify-content: space-around;
}
</style>
