const noUserToFront = {
  beforeRouteEnter (to, from, next) {
    // console.log("BEFORE ROUTE ENTER MIXIN");
    next(vm => {
      const localUser = window.localStorage.getItem("constack");
      if ((localUser !== null) && (vm.$store.state.user === null)) {
        vm.$store.commit("setUser", JSON.parse(window.localStorage.getItem("constack")))
      }
      const user = vm.$store.state.user;
      if (user === null) {
        next({
          path: '/login',
          query: { redirect: to.fullPath }
        })
      }

      if (user && user.user_type < 4) {
        // vm.$router.push("/");
        next("/");
      }
    });
  }
}

export default noUserToFront;